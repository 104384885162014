import { Fragment, useEffect } from 'react';
import useGlobal from "./store/index";
import filterapi from './apis/Filter';
// import resultsapi from './apis/Results';
import Spinner from './components/UI/Spinner/Spinner'
import Errorpage from './components/Errorpage/Errorpage'
import Finder from './components/Finder/Finder';

function App() {
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    let appconfig = { brand: null, reduced: false, data: {}};
    if (typeof(document.getElementById("root").dataset.brand) !== 'undefined') {
      appconfig.brand = document.getElementById("root").dataset.brand;
    }
    if (typeof(document.getElementById("root").dataset.destinationspid) !== 'undefined') {
      appconfig.data.destinationsPid = document.getElementById("root").dataset.destinationspid;
    }
    if (typeof(document.getElementById("root").dataset.tourspid) !== 'undefined') {
      appconfig.data.toursPid = document.getElementById("root").dataset.tourspid;
    }
    if (typeof(document.getElementById("root").dataset.reduced) !== 'undefined') {
      appconfig.reduced = parseInt(document.getElementById("root").dataset.reduced, 10) === 1 ? true : false;
    }
    if (typeof(document.getElementById("root").dataset.redirecturl) !== 'undefined') {
      appconfig.data.redirecturl = document.getElementById("root").dataset.redirecturl;
    }

    globalActions.getTranslations();
    globalActions.filter.initFilter(appconfig);
    globalActions.filter.getFilterdata(filterapi, appconfig);

// let results = document.getElementById("breadcrumbs");
// results.innerHTML += '<p>This is a foobar dummy text from React';


  }, [globalActions]);

  let classesContainer = "w-full px-4";
  if (globalState.appconfig.reduced) {
    classesContainer = "w-full md:px-4";
  }

  return (
    globalState.loading ?
      <Spinner />
      :
      globalState.error ?
        <Errorpage />
        :
        <Fragment>
          <div className={classesContainer}>
            <Finder />
          </div>
        </Fragment>
       
  );
}

export default App;
