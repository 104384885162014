import React, { Fragment } from 'react';
import i18next from 'i18next';

const Errorpage = () => {
    return (
        <Fragment>
            <h1>{i18next.t('main:error.title')}</h1>
            <p>{i18next.t('main:error.mess')}</p>
        </Fragment>
    )
}

export default Errorpage
