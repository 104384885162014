import *  as filter from './filter';
import i18next from 'i18next';
import i18config from '../../config/i18';
import XHR from 'i18next-http-backend';

export const setLoading = (store, type, newstate) => {
    let loadingParts = store.state.loadingParts;
    loadingParts[type] = newstate;
    let loading = false;
    for(let i in loadingParts) {
        if (loadingParts[i]) {
            loading = true;
            break;
        }
    }
    store.setState({ loading, loadingParts });

};

export const getTranslations = async (store) => {
    store.actions.setLoading('lng', true);

    await i18next
    .use(XHR)
    .init(i18config, function (err, t) {
        store.actions.setLoading('lng', false);
    }
    );
}

export { filter };