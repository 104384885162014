import React from "react";
import globalHook from "use-global-hook";

import * as actions from "./actions/index";

const initialState = {
  loading: true,
  loadingParts: {
    lng: true,
    api: false
  },
  loadingResults: false,
  error: false,
  appconfig: {},
  filterdata: {
    destinations: [],
    tags: []
  },
  filterstate: {},
  results: {
    tourIds: [],
    searched: false,
    numTotal: 0,
  }
}

//const useGlobal = globalHook(initialState, actions);
const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
