export const getFilterdata = async (store, filterapi, appconfig) => {
    store.actions.setLoading('api', true);
    let apiError = false;
    let filterdata = {
        destinations: [],
        tags: []
    }
    let filterstate = {
        ...store.state.filterstate,
    };
    let results = {
        ...store.state.results,
    }

    try {
        const response = await filterapi.all(appconfig);
        filterdata.destinations = response.data.destinations;
        filterdata.tags = response.data.tags;
        results.tourIds =  response.data.tourUids;
    }
    catch (error) {
        store.actions.setLoading('api', false);
        apiError = true;
        console.log('API error [getFilterdata]', error)
    }
    store.actions.setLoading('api', false);
    store.setState({
        error: apiError,
        filterdata: filterdata,
        filterstate: filterstate,
        results: results
    });
};

export const setFilterstate = async (store, newstate, resultsapi) => {
    let filterstate = {
        ...store.state.filterstate,
        ...newstate
    };
    
    let appconfig = {
        ...store.state.appconfig
    }

    store.setState({
        filterstate
    });

    let results = {
        ...store.state.results,
        searched: false,
        numTotal: 0
    }

    try {
        const responseTours = await resultsapi.next(appconfig, filterstate, results);

        results.numTotal = responseTours.data.numTotal;
        results.searched = true;

        store.setState({
            loadingResults: false,
            results: results,
        });
    }
    catch (error) {
        store.actions.setLoading('api', false);
        store.setState({
            error: true,
            results: results,
            loadingResults: false
        });
        console.log('API error [getFilterdata]', error)
    }
};

export const initFilter = (store, appconfig) => {
    let filterstate = initFilterstateObject();
    let results = {
        ...store.state.results
    }

    store.setState({
        filterstate: filterstate,
        results: results,
        appconfig: appconfig
    });
}

const initFilterstateObject = () => {
    return {
        country: null,
        tags: [],
        startDate: null,
        endDate: null,
        viewMode: 'finder'
    }
}