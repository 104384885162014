import { useState, useEffect, useRef } from 'react';
import Clearer from '../Clearer/Clearer';
import useGlobal from "../../../store/index";

function getOptionClass(index, length, cursor) {
    let className = "px-4 py-2 hover:bg-gray-300";
    if (index === 0) {
        className += " rounded-t-lg";
    }
    if (index === length - 1) {
        className += " rounded-b-lg";
    }
    if (index === cursor) {
        className += " bg-gray-300";
    }
    return className;
}

const Autocomplete = ({ id, name, value, options, onChange, onFilter, placeholder }) => {
    const [globalState] = useGlobal();
    const [showOptions, setShowOptions] = useState(false);
    const [cursor, setCursor] = useState(-1);
    const [filterMode, setFilterMode] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const ref = useRef();

    useEffect(() => {
        if (!filterMode) {
            setFilteredOptions(options);
        }
        const listener = (e) => {
            if (!ref.current.contains(e.target)) {
                setShowOptions(false);
                setCursor(-1);
            }
        };
        document.addEventListener('click', listener);
        document.addEventListener('focusin', listener);
        return () => {
            document.removeEventListener('click', listener);
            document.removeEventListener('focusin', listener);
        }
    }, [options, filterMode]);

    const selectOption = (option) => {
        onChange(option);
        setShowOptions(false);
        setFilterMode(false);
    };

    const filterOptions = (value) => {
        setFilterMode(true);
        setFilteredOptions(onFilter(options, value));
    }

    const clearFilter = () => {
        setFilterMode(false);
        setFilteredOptions(onFilter(options, ''));
    }

    const checkExactMatch = () => {
        let exactMatch = false;
        if (filterMode) {
            for (let i in options) {
                if (value.trim().toLowerCase() === options[i].name.toLowerCase()) {
                    exactMatch = options[i];
                }
            }
            if (exactMatch !== false) {
                selectOption(exactMatch);
            }
            else {
                //does not work as mouse click triggers blur
                //clearFilter();
            }
        }
    }

    let ulClasses = "absolute shadow-lg hover:shadow-xl rounded-lg bg-white z-10 overflow-y-scroll h-60 max-h-maxh-240 autocomplete-sizer";
    let inputClasses = "focus:ring-green-500 focus:border-green-500 block pr-10 sm:text-sm border-gray-300 rounded-md w-full";
    if (globalState.appconfig.reduced) {
        ulClasses += "-reduced";
        inputClasses = "focus:ring-green-500 focus:border-green-500 block pr-10 text-sm text-center md:text-left md:text-base border-gray-300 rounded-md w-full";
    }

    const cursorMove = (e) => {
        switch (e.code) {
            case "ArrowUp":
                setShowOptions(true);
                if (cursor > 0) {
                    setCursor((c) => c - 1);
                }
                break;
            case "ArrowDown":
                setShowOptions(true);
                if (cursor < filteredOptions.length - 1) {
                    setCursor((c) => c + 1);
                }
                break;
            case "Enter":
                if (cursor !== -1) {
                    selectOption(filteredOptions[cursor]);
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="" ref={ref}>
            <div className="mt-1 relative rounded-md shadow-sm">
                <input
                    autoComplete="off"
                    list="autocompleteOff"
                    id={id}
                    name={name}
                    type="text"
                    className={inputClasses}
                    value={value}
                    onFocus={() => setShowOptions(true)}
                    onChange={(e) => filterOptions(e.target.value)}
                    onBlur={checkExactMatch}
                    onKeyDown={cursorMove}
                    placeholder={placeholder}
                />
                <Clearer onClick={clearFilter} displayCond={value} />
            </div>
            {showOptions && (
            <ul className={ulClasses}>
                {filteredOptions.map((option, idx) => (
                    <li
                        key={idx}
                        className={getOptionClass(idx, options.length, cursor)}
                        onClick={(e) => selectOption(option)}
                    >{option.name}</li>
                ))}
            </ul>
            )}
      </div>
    );
}

export default Autocomplete;