import React from 'react';

import classes from './Spinner.module.css';

const Spinner = () => {
    return (
        <div className={classes.ldsDefault}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Spinner;