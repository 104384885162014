import i18next from 'i18next';

const Badge = ({ uid, name, onClick }) => {
    return (
        <span
            className="inline-flex items-center cursor-pointer px-3 py-1 m-1 rounded-full text-sm font-medium bg-secondary text-white"
            onClick={(e) => onClick(uid)}
        >
            {name}
            <button type="button" className="flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center bg-secondary text-white border border-white hover:bg-primary focus:outline-none focus:bg-secondary focus:text-gray-100">
                <span class="sr-only">{i18next.t('main:finder.tags.remove')}</span>
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
            </button>

        </span>
    );
}

export default Badge;
