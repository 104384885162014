import Api from './Api';

const END_POINT = 'filterdata/finder'

const functions = {
    all(appconfig) {
        return Api.post(END_POINT, { config: appconfig });
    }

};

export default functions;