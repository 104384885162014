import Api from './Api';
import moment from 'moment';

const END_POINT = 'results'

const functions = {
    next(appconfig, filterstate, results) {
        let filterstateCopy = {...filterstate};
        if (filterstate.startDate !== null) {
            filterstateCopy.startDate = moment(filterstate.startDate).format('YYYY-MM-DD');
        }
        if (filterstate.endDate !== null) {
            filterstateCopy.endDate = moment(filterstate.endDate).format('YYYY-MM-DD');
        }
        return Api.post(END_POINT, { config: appconfig, filterstate: filterstateCopy, results: results });
    }

};

export default functions;