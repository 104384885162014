import { XCircleIcon } from '@heroicons/react/solid'

const Clearer = ({onClick, displayCond}) => {
    return (
        displayCond ? 
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center z-9">
                <XCircleIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    onClick={onClick}
                />
            </div>
        : null
    );
}

export default Clearer;